import React from "react";
import "./Common.css";

export const CustomHeading = (props) => {
  return (
    <h3 className="custom-heading" {...props}>
      {props.Heading}
    </h3>
  );
};

export const CustomSubTitle = (props) => {
  return <h6 className="custom-subtitle">{props.SubTitle}</h6>;
};

export const CustomDescription = (props) => {
  return (
    <p className="custom-description" {...props}>
      <span>{props.startIcon}</span> {props.Description}
    </p>
  );
};
